import logo from "./logo.svg";
import "./App.css";
import Webcam from "react-webcam";
import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const App = () => {
  const webcamRef = useRef(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const [responses, setResponses] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const maxScreenshots = 10; // Maximum number of screenshots
  const [selectedIndex, setSelectedIndex] = useState(0);
  const videoConstraints = {
    // width: 720,
    // height: 1280,
    facingMode: "environment", // Use the rear camera
  };
  const [showStartButton, setShowStartButton] = useState(true);

  const capture = React.useCallback(() => {
    if (responses.length >= maxScreenshots) {
      stopCapture();
      return;
    }

    const imageSrc = webcamRef.current.getScreenshot();
    sendToAPI(imageSrc).then((response) => {
      if (response) {
        setResponses((currentResponses) => [
          ...currentResponses,
          { imageSrc, response },
        ]);
      }
    });
  }, [webcamRef, setResponses]);

  const startCapture = () => {
    setShowStartButton(false);
    setIsCapturing(true);
    const id = setInterval(capture, 2000); // capturing every 2 seconds
    setIntervalId(id);
  };

  const stopCapture = () => {
    setIsCapturing(false);
    setIsCapturing(false);
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const sendToAPI = async (base64Image) => {
    const api_key = "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"; // Your API Key here
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    };

    const payload = {
      model: "gpt-4-vision-preview",
      messages: [
        {
          role: "user",
          content: [
            {
              type: "text",
              text: "You are a Ramp Walk Trainer, who trains women for Ramp Walk, Provide a Bulleted list of issues in terms of the Ramp Walk that the Model in Image is doing wrong, provide information like What is wrong and how it can be corrected. give response in bullet points and limit the response to 70 words.",
            },
            {
              type: "image_url",
              image_url: {
                url: `${base64Image}`,
              },
            },
          ],
        },
      ],
      max_tokens: 300,
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      return await response.json();
    } catch (error) {
      console.error("Error sending image to API:", error);
    }
  };

  const restartApp = () => {
    setResponses([]);
    setShowStartButton(true);
  };

  const renderCarousel = () => {
    return (
      <div>
        <button onClick={restartApp} className="home-button">
          Home
        </button>
        <Carousel
          showIndicators={false}
          showArrows={false}
          selectedItem={selectedIndex}
          onChange={(index) => setSelectedIndex(index)}
        >
          {responses.map((response, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={response.imageSrc}
                alt={`Captured ${index}`}
                className="carouselImage"
                style={{
                  maxWidth: "100%",
                  // maxHeight: "50vh",
                  marginBottom: "10px",
                }}
              />
            </div>
          ))}
        </Carousel>
        <div className="marquee">
          <p className="legend" style={{ textAlign: "center" }}>
            <div style={{ whiteSpace: 'pre-line' }}>
              {responses[selectedIndex]?.response?.choices?.length > 0 &&
                responses[selectedIndex]?.response?.choices[0]?.message?.content}
            </div>
          </p>
        </div>
      </div>
    );
  };

  const renderStartScreen = () => (
    <div className="start-screen">
      {/* <img src="./assets/images/fashion.jpg" alt="Background" /> */}
      <video
        id="video"
        playsInline
        preload="none"
        style={{ background: "black" }}
        width="100%"
        height="100%"
        autoPlay
        loop
        muted
      >
        <source src="../assets/videos/mobileVideo.mp4" type="video/mp4" />
      </video>
      {showStartButton && (
        <button className="start-button" onClick={startCapture}>
          START
        </button>
      )}
    </div>
  );

  const renderCameraScreen = () => (
    <div style={{ width: "100%", height: "100vh", overflow: "auto" }}>
      <>
        <Webcam
          style={{ width: "100%", height: "auto" }}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <button
          className={!isCapturing ? "start-button" : "stop-button"}
          onClick={isCapturing ? stopCapture : startCapture}
        >
          {isCapturing ? "STOP" : "START"}
        </button>
      </>
      {/* <Carousel showArrows={true}>
        {responses.map((response, index) => (
          <div key={index}>
            <img src={response.imageSrc} alt={`Captured ${index}`} />
            <p className="legend">{response.response.choices[0].message.content}</p>
          </div>
        ))}
      </Carousel> */}
      {/* <table border={"1"}>
          <thead>
            <tr>
              <th>Image</th>
              <th>Response</th>
            </tr>
          </thead>
          <tbody>
            {responses.map((response, index) => (
              <tr key={index}>
                <td>
                  <img src={response.imageSrc} alt={`Captured ${index}`} style={{ width: '100%', height: 'auto' }} />
                </td>
                <td>
                  {response.response.choices[0].message.content}
                </td>
              </tr>
            ))}
          </tbody>
        </table> */}
    </div>
  );
  return (
    <div className="home">
      {showStartButton && renderStartScreen()}
      {isCapturing && renderCameraScreen()}
      {!showStartButton && !isCapturing && (
        <>
          {responses.length > 0 ? (
            renderCarousel()
          ) : (
            <div className="marquee">
              <button onClick={restartApp} className="home-button">
                Home
              </button>
              <p
                className="legend"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  height: "90vh",
                  fontSize: "24px",
                }}
              >
                Analyzing...
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
  // return (
  //   <div style={{ width: '100%', height: '100vh', overflow: 'auto' }}>
  //     <Webcam style={{ width: '100%' }} ref={webcamRef} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} />
  //     <button onClick={isCapturing ? stopCapture : startCapture}>
  //       {isCapturing ? 'STOP' : 'START'}
  //     </button>
  //     <table border={"1"}>
  //       <thead>
  //         <tr>
  //           <th>Image</th>
  //           <th>Response</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {responses.map((response, index) => (
  //           <tr key={index}>
  //             <td>
  //               <img src={response.imageSrc} alt={`Captured ${index}`} style={{ width: '100%', height: 'auto' }} />
  //             </td>
  //             <td>
  //               {response.response.choices[0].message.content}
  //             </td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );

  // return showStartButton ? renderStartScreen() : renderCameraScreen();

  // return showStartButton ? (
  //   renderStartScreen()
  // ) : (
  //   renderCarousel()
  // );
};

export default App;
